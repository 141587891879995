
import { defineComponent } from 'vue';
import { WorkflowStep } from '@/model/Workflow';
import VideoDatabaseEntry from '@/model/VideoDatabaseEntry';

export interface WorkflowStepCardInfo {
  step: WorkflowStep;
  isComplete: boolean;
  dbEntry: VideoDatabaseEntry | null;
  isExpired: boolean;
  isClickable: boolean;
  isValidAfterExpiredTask: boolean;
  isNextStep: boolean;
  waitingForTimeExpiration: boolean;
  stageIndex: number;
  stepIndex: number;
}

export default defineComponent({
  name: 'WorkflowStepCard',
  props: {
    stepInfo: {
      type: Object as () => WorkflowStepCardInfo,
      required: true,
    },
    stageSecondsRemainingString: {
      type: String,
      required: true,
    },
    nextStepInStage: {
      type: Object,
      default: null,
    },
  },
  emits: ['card-selected'],
});
