

import {
  defineComponent, onBeforeUnmount, onMounted, ref, watch,
} from 'vue';

import webcamProvider from '@/services/WebcamProvider';
import WebcamSourceSelectionMenu from '@/components/elements/WebcamSourceSelectionMenu.vue';

export default defineComponent({
  name: 'WebcamBox',
  components: {
    WebcamSourceSelectionMenu,
  },
  props: {
    showStartWebcamButton: {
      type: Boolean,
      default: true,
    },
    maxHeight: {
      type: String,
    },
  },
  setup() {
    const videoE = ref(null as null | HTMLVideoElement);
    const webcamStartError = ref(null as null | any);

    onMounted(() => {
      if (!videoE.value) throw new Error('videoE is null');
      if (webcamProvider.webcamStatus.value === 'running') {
        webcamProvider.connectVideoElement(videoE.value);
      }
    });
    watch(webcamProvider.webcamStatus, (status) => {
      if (status === 'running' && videoE.value) {
        webcamProvider.connectVideoElement(videoE.value);
      }
    });
    onBeforeUnmount(() => {
      if (!videoE.value) throw new Error('videoE is null');
      webcamProvider.disconnectVideoElement(videoE.value);
    });

    return {
      videoE,
      webcamStartError,
      webcamProvider,
      webcamStatus: webcamProvider.webcamStatus,

      audioDeviceId: ref(''),
      videoDeviceId: ref(''),
    };
  },
  computed: {
    webcamStarted() {
      return (this as any).webcamStatus === 'stopped';
    },
  },
  methods: {
    async selfStartWebcam() {
      await this.startWebcam(this.videoDeviceId, this.audioDeviceId);
    },
    async startWebcam(videoDeviceId: string, audioDeviceId: string) {
      this.webcamStartError = null;

      try {
        await webcamProvider.startWebcam(videoDeviceId, audioDeviceId);
      } catch (e) {
        this.webcamStartError = e;
        return e;
      }

      if (!this.videoE) throw new Error('videoE is null');
      await webcamProvider.connectVideoElement(this.videoE);

      return false;
    },
    playVideo() {
      if (!this.videoE) throw new Error('videoE is null');
      this.videoE.play();
    },
  },
});
