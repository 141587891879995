
import { defineComponent, computed, toRefs } from 'vue';
import MiniLesson, { MiniLessonActivity } from '@/model/MiniLesson';

export interface ProgressSegmentData {
  min: number;
  max: number;
  enabled: boolean;
  label: string;
}

export function calculateProgressSegments(lesson: MiniLesson, activity: MiniLessonActivity) {
  if (!lesson) return [];

  const allLabels = lesson.segmentLabels ?? [];
  let last = undefined as undefined | number;
  const segs = lesson.segmentBreaks.map((timestamp, i) => {
    let segData = undefined as undefined | ProgressSegmentData;
    const label = allLabels[i - 1] ?? '';
    if (last !== undefined) {
      const doesOverlap = Math.max(last, activity.startTime) < Math.min(timestamp, activity.endTime);
      segData = {
        label,
        min: last,
        max: timestamp,
        enabled: activity?.focusedSegments ? activity.focusedSegments.indexOf(i - 1) !== -1 : doesOverlap,
      };
    }
    last = timestamp;
    return segData;
  }).filter((x) => x !== undefined);

  return segs as ProgressSegmentData[];
}

const cssClassOptions = [
  // 'is-light',
  // 'is-info',
  // 'is-success',
  // 'is-warning',
  'is-primary',
  // 'is-danger',
  // '',
];

export default defineComponent({
  name: 'SegmentedProgressBar',
  props: {
    segments: {
      default: Array,
      type: Array,
    },
    progress: {
      default: 0,
      type: Number,
    },
    enableAll: {
      default: false,
      type: Boolean,
    },
  },
  setup(props) {
    const {
      segments,
    } = toRefs(props);

    const segmentsData = computed(
      () => segments.value.map(
        (seg, i) => ({
          id: i,
          cssClass: cssClassOptions[i % cssClassOptions.length],
          ...(seg as ProgressSegmentData),
        }),
      ),
    );

    return {
      segmentsData,
    };
  },
});

